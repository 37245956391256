import React, { useState } from 'react'
import { ClassNames } from '@emotion/react'
import { Popover, Transition } from '@headlessui/react'
import tw from 'twin.macro'

import IoInformationCircleSharp from '../images/svg/IoInformationCircleSharp.svg'

const position = {
  top: tw`bottom[150%]`,
  bottom: tw`top[130%]`,
}

const origin = {
  top: tw`-translate-y-2`,
  bottom: tw`translate-y-2`,
}

export default function InfoTooltip({
  children,
  containerStyles,
  contentStyles,
  isHoverable = true,
  placement = 'bottom',
}) {
  const [show, setShow] = useState(false)

  const handleClick = (e) => {
    e.stopPropagation()
  }

  const handleHover = (e) => {
    e.stopPropagation()
    if (isHoverable) {
      setShow(true)
    }
  }

  const handleLeave = (e) => {
    e.stopPropagation()
    if (isHoverable) {
      setShow(false)
    }
  }

  return (
    <Popover tw="ml-2" css={containerStyles} onClick={handleClick}>
      {({ open }) => (
        <div tw="relative flex">
          <Popover.Button
            tw="text-blue-400 transition-colors hover:text-blue-300 focus:outline-none"
            onPointerEnter={handleHover}
            onPointerLeave={handleLeave}
          >
            <IoInformationCircleSharp tw="w-5 h-5" />
          </Popover.Button>
          <ClassNames>
            {({ css }) => (
              <Transition
                show={isHoverable ? show : open}
                enter={css(
                  tw`absolute transform transition ease-out delay-150`
                )}
                enterFrom={css(tw`opacity-0`, origin[placement])}
                enterTo={css(tw`opacity-100 translate-y-0`)}
                leave={css(tw`transform transition ease-in duration-300`)}
                leaveFrom={css(tw`opacity-100 translate-y-0`)}
                leaveTo={css(tw`opacity-0`, origin[placement])}
                css={[
                  tw`absolute z-50 right-0`,
                  tw`w-72 px-4 py-3 bg-white shadow-lg rounded-md`,
                  tw`font-light text-sm text-grey-2 ring-1 ring-grey-3 ring-opacity-30`,
                  position[placement],
                  contentStyles,
                ]}
              >
                <Popover.Panel static>{children}</Popover.Panel>
              </Transition>
            )}
          </ClassNames>
        </div>
      )}
    </Popover>
  )
}
